import React from "react";
import { graphql } from "gatsby";
import { Row, Col, Layout } from "antd";
import SEO from "../components/seo";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PublicLayout from "../components/PublicLayout";
import DoctorISO9001 from '../images/ISO9001.webp';
import VectorsISO27001 from '../images/ISO27001.webp';
import IQNET from '../images/IQNET.png';
import Iso9001quality from '../images/Iso9001quality.png';
import Iso27001 from '../images/Iso27001.png';

const { Content } = Layout;

const Certifications = () => {
  const { t } = useTranslation();

  const schemaExtraData = [{
    "@type": "Organization",
    logo: "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    sameAs: [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador",
    ],
  }];


  return (
  <PublicLayout>
    <SEO
      title={t('seo.certifications.title')}
      description={t('seo.certifications.description')}
      dataLayer={{
        userId: "",
        pageId: "certifications",
        pageType: "page",
        category: "",
      }}
      schemaExtraData={schemaExtraData}
    />
    <Layout className="certifications-layout">
      <Content>
        <Row justify='center' className="certifications-page-container mt-lg-100 mt-md-40 mt-sm-40 mt-xs-40 mb-lg-200 mb-md-60 mb-sm-60 mb-xs-60">
          <Col xxl={15} xl={19} lg={19} xs={22}>
            <h1 className="text-center mb-lg-40 mb-xs-30">{t('certifications.title')}</h1>
          </Col>
          <Col xxl={15} xl={17} lg={21} xs={22}>
            <h2 className="text-center mb-lg-60 mb-xs-30">{t('certifications.subTitle')}</h2>
          </Col>
          <Col xxl={15} xl={19} lg={22} xs={22} className='card-wrapper'>
            <Row justify='center'>
              <Col lg={22} md={19} sm={20} xs={21}>
                <Row justify='space-between'>
                  <Col lg={8} xs={24} className='image-container'>
                    <img src={DoctorISO9001} alt='ISO 9001' />
                  </Col>
                  <Col lg={14} xs={24} className="pr-lg-40">
                    <Row gutter={[0, 30]}>
                      <Col lg={24} xs={24}>
                        <h3>{t('certifications.firstCardTitle')}</h3>
                        <p dangerouslySetInnerHTML={{ __html: t('certifications.firsCardContent') }} />
                      </Col>
                      <Col lg={24} xs={24}>
                        <Row>
                          <img src={Iso9001quality} alt='Logo ISO 9001 Calidad' width={220} />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xxl={15} xl={19} lg={22} xs={22} className='card-wrapper mt-lg-140 mt-xs-30'>
            <Row justify='center'>
              <Col lg={22} md={19} sm={20} xs={21}>
                <Row justify='space-between'>
                  <Col lg={8} xs={24}  className='image-container'>
                    <img src={VectorsISO27001} alt='Vectores' />
                  </Col>
                  <Col lg={14} xs={24}>
                    <Row gutter={[0, 20]}>
                      <Col lg={24} xs={24} className="pr-lg-40">
                        <h3>{t('certifications.secondCardTitle')}</h3>
                        <p dangerouslySetInnerHTML={{ __html: t('certifications.secondCardContent') }} />
                      </Col>
                      <Col lg={24} xs={24}>
                        <Row align='middle' gutter={30}>
                          <Col>
                            <img src={Iso27001} alt='Logo ISO 27001' width={100} />
                          </Col>
                          <Col>
                            <img src={IQNET} alt='Logo IQNET' width={91} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  </PublicLayout>
  );
};

export default Certifications;

export const postQuery = graphql`
  query GET_CERTIFICATIONS($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;